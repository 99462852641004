import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase/config';

import styles from './Installments.module.css';

import { fromRefsToIds } from '../hotels/Hotels';

const { todayISO, fromISODateToItalian } = require('../../common/iso_dates');

function InstallmentList({ id, state, stateSubstitute, SHORT_KEY_LENGTH }) {
  let installment = state.installments[id];
  
  function collectInstallment() {
    try {
      fetch(`/v1/collect?installment_id=${id}`).then(() => {
        try {
          getDoc(doc(db, 'installments', id)).then((installment_collection) => {
            let installment_to_substitute = fromRefsToIds(installment_collection.data()); // because installment_collection has a reference
            stateSubstitute(`installments:${id}`, installment_to_substitute);
            stateSubstitute(`enabled_installments:${id}`, true);
          });
        } catch (error) {
          // catch the error during the get of db
          console.error(error);
        }
      });
    } catch (error) {
      // catch the error during the fetch
      console.error(error);
    }
  }

  function installmentPlannedValidButNotPayable() {
    return (
      state.hotels[state.reservations[installment.reservation_id].hotel_id].valid &&
      state.reservations[installment.reservation_id].valid &&
      installment.date.localeCompare(todayISO()) > 0 // localeCompare because installment.date and taday() are strings
    );
  }

  function installmentPlannedValidAndPayable() {
    return (
      state.hotels[state.reservations[installment.reservation_id].hotel_id].valid &&
      state.reservations[installment.reservation_id].valid &&
      installment.date.localeCompare(todayISO()) <= 0
    );
  }

  function installmentCancelled() {
    return (
      !state.hotels[state.reservations[installment.reservation_id].hotel_id].valid ||
      !state.reservations[installment.reservation_id].valid
    );
  }

  function WithdrawalButton({ outerText, innerText }) {
    return <p className={styles.item}>
      {outerText}
      <button
        onClick={() => {
          stateSubstitute(`enabled_installments:${id}`, false); //avoid additional clicks while installment is collected
          collectInstallment();
        }}
        disabled={!state.enabled_installments[id]}
        className={styles['btn-collect']}>
        {state.enabled_installments[id] ? (
          <img className={styles['svg-collect']} src={'/svg/moneys.svg'} alt='' />
        ) : (
          <img
            className={styles['infinity-load']}
            src={'/images/infinity-loading-small.gif'}
            alt='infinity load'
          />
        )}
        {state.enabled_installments[id] ? innerText : 'In corso'}
      </button>
    </p>;
  }
  
    return (
    <>
      {!!state.installments && (
        <div className={styles.container}>
          <p className={styles.item}>{id.slice(0, SHORT_KEY_LENGTH)}</p>
          <p className={styles.item}>{state.hotels[state.reservations[installment.reservation_id].hotel_id].name}</p>
          <p className={styles.item}>{installment.reservation_id.slice(0, SHORT_KEY_LENGTH)}</p>
          <p className={styles.item}>{`${
            state.customers[state.reservations[installment.reservation_id].customer_id].first_name
          } ${state.customers[state.reservations[installment.reservation_id].customer_id].last_name}`}</p>
          <p className={styles.item}>{fromISODateToItalian(installment.date)}</p>
          <p className={styles.item}>{'€ ' + installment.amount}</p>
          <>
            {installment.status === 'paid' ? (
              <p className={styles.item}>{`Pagata${installment.collected_on?` ${installment.collected_on.slice(0,10)}`:''}`}</p>
            ) : installment.status === 'failed' ? (
              <WithdrawalButton outerText={`Fallita${installment.message?` (${installment.message})`:''}`} innerText="Riprova"/>
            ) : installmentPlannedValidButNotPayable() ? (
              <p className={styles.item}>Pianificata</p>
            ) : installmentPlannedValidAndPayable() ? (
              <WithdrawalButton outerText="Pagabile" innerText="Preleva"/>
            ) : installmentCancelled() ? (
              <p className={styles.item}>Annullata</p>
            ) : (
              ''
            )}
          </>
        </div>
      )}
    </>
  );
}

export default InstallmentList;
