import styles from './Hotels.module.css';

import { db } from '../../firebase/config';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function ModifyHotel({ id, state, stateSubstitute }) {
  const MySwal = withReactContent(Swal);
  let hotel_being_modified = { ...state.hotels[id] };

  function modifyHotel() {
    try {
      setDoc(doc(db, 'hotels', id), {
        ...hotel_being_modified,
      }).then(() => {
        stateSubstitute(`hotels:${id}`, hotel_being_modified);
      });
    } catch (error) {
      console.error(error);
    }
  }

  function fireSwalToModifyHotel() {
    MySwal.fire({
      html: (
        <div>
          <h2>Modifica Hotel</h2>
          <form className={styles.form}>
            <div className={styles.checkbox}>
              <input type='checkbox' id='valid' />&nbsp;Attivo
            </div>
            <div>
              <label>Nome</label>
              <input type='text' id='name' />
              <p className={styles.error} id='error_name'></p>
            </div>
            <div>
              <label>Note</label>
              <textarea id='note' />
            </div>
            <div>
              <label>Account Stripe</label>
              <input type='text' id='stripe_account' />
            </div>
            <hr/>
            <div>
              <label>Account Scalapay</label>
              <input type='text' id='scalapay_key' />
            </div>
            <hr/>
            <h3>Account Klarna</h3>
            <p className={styles.error} id='error_klarna'></p>
            <div className={styles.checkbox}>
              <input type="checkbox" id="klarna_credentials_active" onChange={() => {
                MySwal.getPopup().querySelector('#klarna_credentials').style.display =
                  MySwal.getPopup().querySelector('#klarna_credentials_active').checked ? 'block' : 'none';
                MySwal.getPopup().querySelector('#error_klarna').innerHTML = '';
              }}/>&nbsp;Attivo
            </div>
            <span id="klarna_credentials">
              <div>
                <label>Nome utente</label>
                <input type="text" id="klarna_credentials_username"/>
              </div>
              <div>
                <label>Password</label>
                <input type="text" id="klarna_credentials_password"/>
              </div>
              <div>
                <label>ID client</label>
                <input type="text" id="klarna_credentials_client_id"/>
              </div>
              <div>
                <label>Via</label>
                <input type="text" id="address_street"/>
              </div>
              <div>
                <label>CAP</label>
                <input type="text" id="address_zip"/>
              </div>
              <div>
                <label>Citt&agrave;</label>
                <input type="text" id="address_city"/>
              </div>
              <div>
                <label>Nazione</label>
                <input type="text" id="address_country"/>
              </div>
            </span>
            <hr/>
            <h3>Account HeyLight</h3>
            <p className={styles.error} id='error_heylight'></p>
            <div className={styles.checkbox}>
              <input type="checkbox" id="heylight_credentials_active" onChange={() => {
                MySwal.getPopup().querySelector('#heylight_credentials').style.display =
                  MySwal.getPopup().querySelector('#heylight_credentials_active').checked ? 'block' : 'none';
                MySwal.getPopup().querySelector('#error_heylight').innerHTML = '';
              }}/>&nbsp;Attivo
            </div>
              <span id="heylight_credentials">
              <div className={styles.checkbox}>
                <input type="checkbox" id="heylight_credentials_is_financing" />&nbsp;HeyLight finanziamento
              </div>
              <div>
                <label>Merchant Id</label>
                <input type='text' id='heylight_credentials_merchant_key' />
              </div>
              <div>
                <label>Public API Key</label>
                <input type='text' id='heylight_credentials_public_api_key' />
              </div>
            </span>
          </form>
        </div>
      ),
      confirmButtonText: 'Conferma',
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      didOpen: () => {
        MySwal.getPopup().querySelector('#klarna_credentials').style.display = hotel_being_modified.klarna_credentials?.active ? 'block' : 'none';
        MySwal.getPopup().querySelector('#name').value = hotel_being_modified.name;
        MySwal.getPopup().querySelector('#note').value = hotel_being_modified.note;
        MySwal.getPopup().querySelector('#stripe_account').value = hotel_being_modified.stripe_account;
        MySwal.getPopup().querySelector('#valid').checked = hotel_being_modified.valid;
        MySwal.getPopup().querySelector('#scalapay_key').value = hotel_being_modified.scalapay_key;
        MySwal.getPopup().querySelector('#klarna_credentials_active').checked = !!hotel_being_modified.klarna_credentials?.active;
        MySwal.getPopup().querySelector('#klarna_credentials_username').value = hotel_being_modified.klarna_credentials?.username??'';
        MySwal.getPopup().querySelector('#klarna_credentials_password').value = hotel_being_modified.klarna_credentials?.password??'';
        MySwal.getPopup().querySelector('#klarna_credentials_client_id').value = hotel_being_modified.klarna_credentials?.client_id??'';
        MySwal.getPopup().querySelector('#address_street').value = hotel_being_modified.address?.street??'';
        MySwal.getPopup().querySelector('#address_zip').value = hotel_being_modified.address?.zip??'';
        MySwal.getPopup().querySelector('#address_city').value = hotel_being_modified.address?.city??'';
        MySwal.getPopup().querySelector('#address_country').value = hotel_being_modified.address?.country??'';
        MySwal.getPopup().querySelector('#heylight_credentials_active').checked = !!hotel_being_modified.heylight_credentials?.active;
        MySwal.getPopup().querySelector('#heylight_credentials_is_financing').checked = !!hotel_being_modified.heylight_credentials?.is_financing;
        MySwal.getPopup().querySelector('#heylight_credentials_merchant_key').value = hotel_being_modified.heylight_credentials?.merchant_key??'';
        MySwal.getPopup().querySelector('#heylight_credentials_public_api_key').value = hotel_being_modified.heylight_credentials?.public_api_key??'';
      },
      preConfirm: async () => {
        hotel_being_modified.name = MySwal.getPopup().querySelector('#name').value;
        hotel_being_modified.note = MySwal.getPopup().querySelector('#note').value;
        hotel_being_modified.stripe_account = MySwal.getPopup().querySelector('#stripe_account').value;
        hotel_being_modified.valid = MySwal.getPopup().querySelector('#valid').checked;
        hotel_being_modified.scalapay_key = MySwal.getPopup().querySelector('#scalapay_key').value;
        const klarna_credentials = {
          active: MySwal.getPopup().querySelector('#klarna_credentials_active').checked,
          username: MySwal.getPopup().querySelector('#klarna_credentials_username').value,
          password: MySwal.getPopup().querySelector('#klarna_credentials_password').value,
          client_id: MySwal.getPopup().querySelector('#klarna_credentials_client_id').value,
        };
        const address = {
          street: MySwal.getPopup().querySelector('#address_street').value,
          zip: MySwal.getPopup().querySelector('#address_zip').value,
          city: MySwal.getPopup().querySelector('#address_city').value,
          country: MySwal.getPopup().querySelector('#address_country').value,
        };
        const heylight_credentials = {
          active: MySwal.getPopup().querySelector('#heylight_credentials_active').checked,
          is_financing: MySwal.getPopup().querySelector('#heylight_credentials_is_financing').checked,
          merchant_key: MySwal.getPopup().querySelector('#heylight_credentials_merchant_key').value,
          public_api_key: MySwal.getPopup().querySelector('#heylight_credentials_public_api_key').value
        };

        let error_name = MySwal.getPopup().querySelector('#error_name');

        // reset the error text
        error_name.innerHTML = '';
        MySwal.getPopup().querySelector('#name').classList = '';

        if (!hotel_being_modified.name) {
          error_name.innerHTML = 'Campo obbligatorio';
          MySwal.getPopup().querySelector('#name').classList = 'error';
          return false;
        }
        if (hotel_being_modified.name !== state.hotels[id].name) {
          let hotels_names = await getDocs(
            query(collection(db, 'hotels'), where('name', '==', hotel_being_modified.name))
          );
          if (hotels_names.size) {
            MySwal.getPopup().querySelector('#name').classList = 'error';
            error_name.innerHTML = 'Nome già esistente';
            return false;
          }
        }

        const n_defined_klarna_fields = Object.values(klarna_credentials).filter(v => !!v).length;
        const n_defined_address_fields = Object.values(address).filter(v => !!v).length;
        if (klarna_credentials.active && (n_defined_klarna_fields < 4 || n_defined_address_fields < 4)) {
          MySwal.getPopup().querySelector('#error_klarna').innerHTML = 'Per attivare Klarna, è necessario impostare tutti i campi relativi alle credenziali e all\'indirizzo dell\'hotel.<br/>In alternativa, levare la spunta di attivazione.';
          return false;
        }

        if (n_defined_klarna_fields) {
          hotel_being_modified.klarna_credentials = klarna_credentials;
        } else {
          delete hotel_being_modified.klarna_credentials;
        }

        if (n_defined_address_fields) {
          hotel_being_modified.address = address;
        } else {
          delete hotel_being_modified.address;
        }

        const n_defined_heylight_fields = Object.values(heylight_credentials).filter(v => !!v).length;
        if (n_defined_heylight_fields) {
          hotel_being_modified.heylight_credentials = heylight_credentials;
        } else {
          delete hotel_being_modified.heylight_credentials;
        }
        if (heylight_credentials.active && (heylight_credentials.merchant_id === '')) {
          MySwal.getPopup().querySelector('#error_heylight').innerHTML = 'Per attivare HeyLight, è necessario impostare almeno il campo relativo al Merchant ID dell\'hotel.<br/>In alternativa, levare la spunta di attivazione.';
          return false;
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        modifyHotel();
        MySwal.fire({
          icon: 'success',
          title: 'Operazione Confermata',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  }

  return (
    <button className={styles['btn-modify']} onClick={fireSwalToModifyHotel}>
      <img src={'/svg/pencil.svg'} style={{ width: '1.5em' }} alt='' /> Modifica
    </button>
  );
}

export default ModifyHotel;
