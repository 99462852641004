import InstallmentList from './InstallmentList';
import styles from './Installments.module.css';

const { todayISO } = require('../../common/iso_dates');

function Installments({ state, stateAdd, stateDelete, stateSubstitute, SHORT_KEY_LENGTH, MAX_TABLE_ROWS_FOR_PAGE }) {
  const installments_filtred = Object.entries(state.installments)
    .filter(([installment_id, installment]) => installmentMatchesSearch(installment_id, installment));

  const installments_sorted = installments_filtred.sort(
    (
      [first_installment_id, { ...first_installment_value }], 
      [second_installment_id, { ...second_installment_value }]
    ) => first_installment_value.date.localeCompare(second_installment_value.date)); 
    // because installments_filterd is eqaul to an array of array [key: value], example of an element of installments_filterd 0:['BHV1Rzy71w2QxhZralmA', {…}]

  const installments_sliced = installments_sorted.slice(0, MAX_TABLE_ROWS_FOR_PAGE);

  function installmentMatchesSearch(installment_id, installment) { //ANDREI-OK
    let search = state.search.installments;
    let hotel_name = `${state.hotels[state.reservations[installment.reservation_id].hotel_id].name} #${state.reservations[installment.reservation_id].hotel_id.slice(0, SHORT_KEY_LENGTH)}`;

    let customer_name = `${state.customers[state.reservations[installment.reservation_id].customer_id].first_name
      } ${state.customers[state.reservations[installment.reservation_id].customer_id].last_name}`;

    if (
      (search.id &&
        (!installment_id || !installment_id.slice(0, SHORT_KEY_LENGTH).toLowerCase().includes(search.id.toLowerCase()))) ||
      (search.hotel_name &&
        (!hotel_name ||
          !hotel_name.toLowerCase().includes(search.hotel_name.toLowerCase()))) ||
      (search.reservation_id &&
        (!installment.reservation_id ||
          !installment.reservation_id.slice(0, SHORT_KEY_LENGTH).toLowerCase()
            .includes(search.reservation_id.toLowerCase()))) ||
      (search.customer_name &&
        (!customer_name ||
          !customer_name.toLowerCase().includes(search.customer_name.toLowerCase()))) ||
      (search.amount &&
        (!installment.amount ||
          !installment.amount.toLowerCase().includes(search.amount.toLowerCase()))) ||
      (search.date &&
        (!installment.date ||
          !installment.date.toLowerCase().includes(search.date.toLowerCase()))) ||
      !checkInstallmentStatus(installment, search)
    ) {
      return false;
    }
    return true;
  }

  function installmentPlannedValidButNotPayable(installment) {
    return (
      state.hotels[state.reservations[installment.reservation_id].hotel_id].valid &&
      state.reservations[installment.reservation_id].valid &&
      installment.date > todayISO()
    );
  }

  function installmentPlannedValidAndPayable(installment) {
    return (
      state.hotels[state.reservations[installment.reservation_id].hotel_id].valid &&
      state.reservations[installment.reservation_id].valid &&
      installment.date <= todayISO()
    );
  }

  function installmentCancelled(installment) {
    return (
      !state.hotels[state.reservations[installment.reservation_id].hotel_id].valid ||
      !state.reservations[installment.reservation_id].valid
    );
  }

  function checkInstallmentStatus(installment, search) {
    if (search.status === 'all') {
      return true;
    }
    if (search.status === 'paid') {
      if (installment.status === 'paid') {
        return true;
      } else {
        return false;
      }
    }
    if (search.status === 'failed') {
      if (installment.status === 'failed') {
        return true;
      } else {
        return false;
      }
    }
    if (search.status === 'planned') {
      if (!installment.status && installmentPlannedValidButNotPayable(installment)) {
        return true;
      } else {
        return false;
      }
    }
    if (search.status === 'payable') {
      if (!installment.status && installmentPlannedValidAndPayable(installment)) {
        return true;
      } else {
        return false;
      }
    }
    if (search.status === 'canceled') {
      if (!installment.status && installmentCancelled(installment)) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <>
      <h1>Rate</h1>
      <div className={`${styles.container} ${styles.title}`}>
        <p className={styles.item}>ID Rata</p>
        <p className={styles.item}>Hotel</p>
        <p className={styles.item}>Prenotazione</p>
        <p className={styles.item}>Cliente</p>
        <p className={styles.item}>Data</p>
        <p className={styles.item}>Importo</p>
        <p className={styles.item}>Stato</p>
      </div>
      <div className={`${styles.container} ${styles.filter}`}>
        <input
          type='text'
          className={styles.item}
          onChange={(e) => {
            stateSubstitute('search:installments:id', e.target.value);
          }}
          value={state.search.installments.id}
          placeholder='Cerca per id'
        />
        <input
          type='text'
          className={styles.item}
          onChange={(e) => {
            stateSubstitute('search:installments:hotel_name', e.target.value);
          }}
          value={state.search.installments.hotel_name}
          placeholder='Cerca per hotel'
        />
        <input
          type='text'
          className={styles.item}
          onChange={(e) => {
            stateSubstitute('search:installments:reservation_id', e.target.value);
          }}
          value={state.search.installments.reservation_id}
          placeholder='Cerca per prenotazione'
        />
        <input
          type='text'
          className={styles.item}
          onChange={(e) => {
            stateSubstitute('search:installments:customer_name', e.target.value);
          }}
          value={state.search.installments.customer_name}
          placeholder='Cerca per cliente'
        />
        <input
          type='text'
          className={styles.item}
          onChange={(e) => {
            stateSubstitute('search:installments:date', e.target.value);
          }}
          value={state.search.installments.date}
          placeholder='Cerca per data'
        />
        <input
          type='text'
          className={styles.item}
          onChange={(e) => {
            stateSubstitute('search:installments:amount', e.target.value);
          }}
          value={state.search.installments.amount}
          placeholder='Cerca per prezzo'
        />
        <select
          className={styles.item}
          onChange={(e) => {
            stateSubstitute('search:installments:status', e.target.value);
          }}
          value={state.search.installments.status}>
          <option value='all'></option>
          <option value='paid'>Pagata</option>
          <option value='failed'>Fallita</option>
          <option value='planned'>Pianificata</option>
          <option value='payable'>Pagabile</option>
          <option value='canceled'>Annullata</option>
        </select>
      </div>
      {installments_sliced.length ? (
        <>
          {installments_filtred.map(([id]) => (
            <InstallmentList
              key={id}
              id={id}
              state={state}
              stateSubstitute={stateSubstitute}
              SHORT_KEY_LENGTH={SHORT_KEY_LENGTH}
            />
          ))}
        </>
      ) : (
        <p>Nessuna Rate da visualizzare</p>
      )}
    </>
  );
}

export default Installments;
