import { NavLink } from 'react-router-dom';
import styles from './Hotels.module.css'
import ModifyHotel from './ModifyHotel';
import * as XLSX from 'xlsx';
const { todayISO } = require('../../common/iso_dates');

function HotelList({ id, state, stateSubstitute, SHORT_KEY_LENGTH }) {
  let hotel = state.hotels[id];

  function installmentPlannedValidButNotPayable(installment) {
    return (
      state.hotels[state.reservations[installment.reservation_id].hotel_id].valid &&
      state.reservations[installment.reservation_id].valid &&
      installment.date.localeCompare(todayISO()) > 0 // localeCompare because installment.date and taday() are strings
    );
  }

  function installmentPlannedValidAndPayable(installment) {
    return (
      state.hotels[state.reservations[installment.reservation_id].hotel_id].valid &&
      state.reservations[installment.reservation_id].valid &&
      installment.date.localeCompare(todayISO()) <= 0
    );
  }

  function installmentCancelled(installment) {
    return (
      !state.hotels[state.reservations[installment.reservation_id].hotel_id].valid ||
      !state.reservations[installment.reservation_id].valid
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.item}>{id}</p>
      <p className={styles.item}>{hotel.private_key}</p>
      <p className={styles.item}>{hotel.name}</p>
      <p className={styles.item}>{hotel.note}</p>
      <p className={styles.item}>{hotel.stripe_account}</p>
      <p className={styles.item}>{hotel.scalapay_key}</p>
      <p className={styles.item}>{(hotel.klarna_credentials?.active && hotel.klarna_credentials.username) || ''}</p>
      <p className={styles.item}>{(hotel.heylight_credentials?.active && hotel.heylight_credentials.merchant_key) || ''}</p>
      <p className={styles.item}>{hotel.valid ? 'Sì' : 'No'}</p>
      <div className={styles['download-container']}>
        <NavLink to={`/reservations/`} onClick={() => stateSubstitute('search:reservations:hotel_name', id.slice(0, SHORT_KEY_LENGTH))} className={styles.item + ' link'}>Vedi</NavLink>
        <button className={styles['download-button']}
          onClick={() => {
            let installments = [];
            Object.entries(state.installments).forEach(([installment_id, installment]) => {
              if (state.reservations[installment.reservation_id].hotel_id === id) {
                let reservation_id = installment.reservation_id;
                let reservation = state.reservations[reservation_id];
                let customer = state.customers[reservation.customer_id];
                // in italian the name of variable because they will be the header of excel
                installments.push({
                  'ID prenotazione': reservation_id,
                  'Stato prenotazione': reservation.valid,
                  'Nome cliente': `${customer.last_name} ${customer.first_name}`,
                  'Email cliente': customer.email,
                  'Telefono cliente': customer.phone,
                  'Data prenotazione': reservation.timestamp,
                  'Data check-in': reservation.checkin,
                  'Data check-out': reservation.checkout,
                  'Prezzo (€)': reservation.price,
                  'ID rata': installment_id,
                  'Data scadenza rata': installment.date,
                  'Importo rata (€)': installment.amount,
                  'Stato rata': installment.status,
                })
              }
            });

            installments.sort((first_row, second_row) => {
              let result = first_row['Data prenotazione'].localeCompare(second_row['Data prenotazione']);
              if (result !== 0) {
                return result;
              }
              return first_row['Data scadenza rata'].localeCompare(second_row['Data scadenza rata']);
            });

            installments.forEach((installment) => {
              installment['ID prenotazione'] = installment['ID prenotazione'].slice(0, SHORT_KEY_LENGTH);
              installment['Stato prenotazione'] = installment['Stato prenotazione'] ? 'Valida' : 'Annullata';
              installment['Data prenotazione'] = new Date(installment['Data prenotazione']);
              installment['Data check-in'] = new Date(installment['Data check-in']);
              installment['Data check-out'] = new Date(installment['Data check-out']);
              installment['Stato rata'] = installment['Stato rata']  === 'paid' ? 'Pagato' : installment['Stato rata'] === 'failed' ? 'Fallita' : installmentPlannedValidButNotPayable(state.installments[installment['ID rata']]) ? 'Pianificata' : installmentPlannedValidAndPayable(state.installments[installment['ID rata']]) ? 'Pagabile' : installmentCancelled(state.installments[installment['ID rata']]) ? 'Annullata' : '';
              installment['ID rata'] = installment['ID rata'].slice(0, SHORT_KEY_LENGTH);
              installment['Data scadenza rata'] = new Date(installment['Data scadenza rata']);
              installment['Prezzo (€)'] = Number(installment['Prezzo (€)']);
              installment['Importo rata (€)'] = Number(installment['Importo rata (€)']);
            });

            const newBook = XLSX.utils.book_new();
            const newSheet = XLSX.utils.json_to_sheet(installments);

            XLSX.utils.book_append_sheet(newBook, newSheet, 'Prenotazioni');
            XLSX.writeFile(newBook, `Holipay - ${hotel.name} ${todayISO()}.xlsx`);
          }}>
          <img src={'/svg/file-excel-solid.svg'} style={{ width: '1em' }} alt='Excel icon' />
          <div className={styles['download-write']}>Scarica</div>
        </button>
      </div>
      <ModifyHotel id={id} state={state} stateSubstitute={stateSubstitute} />
    </div>
  );
}

export default HotelList;
