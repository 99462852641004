import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { collection, getDocs } from 'firebase/firestore';

import { fromRefsToIds } from '../pages/hotels/Hotels';
import { db } from '../firebase/config';
import styles from './Navbar.module.css';

function Navbar({ stateSubstitute }) {
  let navigate = useNavigate();

  // getting the hotels, reservations and installments collections from the database,
  // made here the fetch to prevent to make again the fetch of data when re-render the
  // hotels page
  useEffect(
    () => {
      let query_hotels = getDocs(collection(db, 'hotels'));
      let query_reservations = getDocs(collection(db, 'reservations'));
      let query_installments = getDocs(collection(db, 'installments'));
      let query_customers = getDocs(collection(db, 'customers'));

      Promise.all([query_hotels, query_reservations, query_installments, query_customers]).then(
        ([hotels, reservations, installments, customers]) => {
          let hotels_to_add = {};
          hotels.forEach((hotel) => (hotels_to_add[hotel.id] = fromRefsToIds(hotel.data())));

          let reservations_to_add = {};
          reservations.forEach(
            (reservation) => (reservations_to_add[reservation.id] = fromRefsToIds(reservation.data()))
          );

          let installments_to_add = {};
          let enabled_installments_to_add = {};
          installments.forEach((installment) => {
            installments_to_add[installment.id] = fromRefsToIds(installment.data());
            enabled_installments_to_add[installment.id] = true;
          });

          let customers_to_add = {};
          customers.forEach((customer) => (customers_to_add[customer.id] = fromRefsToIds(customer.data())));

          stateSubstitute('hotels', hotels_to_add);
          stateSubstitute('reservations', reservations_to_add);
          stateSubstitute('installments', installments_to_add);
          stateSubstitute('customers', customers_to_add);
          stateSubstitute('enabled_installments', enabled_installments_to_add);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function goToHotelsPage() {
    // reset all the filters when clicked on button
    stateSubstitute('search:hotels', {
      id: '',
      hotel_name: '',
      hotel_private_key: '',
      stripe_account: '',
      note: '',
      valid: 'all',
      scalapay_key: '',
      klarna_key: '',
    });
    navigate('/');
  }

  function goToReservationsPage() {
    // reset all the filters when clicked on button
    stateSubstitute('search:reservations', {
      id: '',
      hotel_name: '',
      checkin: '',
      checkout: '',
      customer_name: '',
      price: '',
      timestamp: '',
      valid: 'all',
    });
    navigate('/reservations');
  }

  function goToInstallmentsPage() {
    // reset all the filters when clicked on button
    stateSubstitute('search:installments', {
      id: '',
      hotel_name: '',
      customer_name: '',
      amount: '',
      date: '',
      reservation_id: '',
      status: 'all',
    });
    navigate('/installments');
  }

  return (
    <div>
      <button className={styles['btn-nav']} onClick={goToHotelsPage}>
        Hotel
      </button>
      <button className={styles['btn-nav']} onClick={goToReservationsPage}>
        Prenotazioni
      </button>
      <button className={styles['btn-nav']} onClick={goToInstallmentsPage}>
        Rate
      </button>
    </div>
  );
}

export default Navbar;