const DAY_MS = 86400000;

// input UCT string (or UTC date object) like '2021-12-14T15:28:09.653Z' to Italian local time including hous --> '4/12/2021, 16:28:09'
function fromLongUTCISOToItalian(utc_iso){
  let result = (typeof utc_iso === "string" ? new Date(utc_iso) : utc_iso).toLocaleString('it-IT', {timeZone: 'Europe/Rome'});   
  return result;
}

/**
 * returns the first day of the month, if date_iso not present, then first day of current month
 * startOfMonthISO("2021-03-10") --> "2021-03-01"
 * @function startOfMonthISO
 * @param  {string} date_iso 'YYYY-MM-DD'
 * @return {string} 'YYYY-MM-DD'
 */
function startOfMonthISO(date_iso){
  if(typeof date_iso == 'undefined')
      date_iso = todayISO(); //new Date().toISOString().slice(0,10);
  
  let parts = date_iso.split('-');
  if(parts.length !== 3) return null; 
  return parts[0]+'-'+parts[1]+'-01';
}
/**
 * add (or subtract if nagative) days to start_iso, return new iso_date
 * e.g. daysAddISO('2021-03-01',3) --> '2021-03-04'
 * @function daysAddISO
 * @param  {string} start_iso 'YYYY-MM-DD'
 * @param  {number} days      number of days to add (or substract)
 * @return {string} 'YYYY-MM-DD'
 */
function daysAddISO(start_iso, days){
  days = Number(days); 
  let day_epoch = new Date(start_iso).getTime();
  return new Date(day_epoch + days * DAY_MS).toISOString().slice(0,10); 
}

/**
 * returns today in ISO  todayISO() --> '2021-03-12'
 * @function todayISO
 * @return {string} 
 */
function todayISO(){
  let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  let localISOTime = (new Date(Date.now() - tzoffset)).toISOString();

  return localISOTime.slice(0,10);
}

// first_iso - second_iso, maggiore di 1 se first e' successivo
function differenceInMonthsISO(first_iso,second_iso){
  return Number(first_iso.split('-')[1]) - Number(second_iso.split('-')[1]) + //months
    12 * (Number(first_iso.split('-')[0]) - Number(second_iso.split('-')[0]) ); //years
 }

 /**
 * find difference in days: first_iso - second_iso: NOTE: values can be positive or negative, (first_iso - second_iso)
 * e.g. console.log(differenceInDaysISO('2021-03-12','2021-03-10')); --> 2
 * @function differenceInDaysISO
 * @param  {string} first_iso  'YYYY-MM-DD'
 * @param  {string} second_iso 'YYYY-MM-DD'
 * @return {number} 
 */
function differenceInDaysISO(first_iso,second_iso){
  return Math.floor((new Date(first_iso).getTime() - new Date(second_iso).getTime())/DAY_MS);
}

/**
 * transform a general ISO date string like '2020-08-29' or 1996-10-15T00:05:32.000Z into the Italian string '29/08/2020'
 * @function fromISODateToItalian
 * @param {string} iso_Date 
 * @return {string}
 */
 function fromISODateToItalian(iso_Date){
  if(iso_Date.length>10) //assume ISO date like dateobj.toISOString() --> 1996-10-15T00:05:32.000Z
  iso_Date = iso_Date.split('T')[0];

  let splitted_date = iso_Date.split('-');
  if(splitted_date.length !== 3) return ''; //for some reason the number of pieces could be less than 3

  splitted_date = [splitted_date[2], splitted_date[1], splitted_date[0]];
  return splitted_date.join('/');
}

/**
 * 
 * @returns {string} ISO timestamp of current date and time
 */
function timestampLongUTCISO(){
  return new Date().toISOString(); 
}

/**
 * 
 * @param {string?} iso_timestamp 
 * @returns {float} Epoch of the timestamp (or current time if iso_timestamp is undefined)
 */
function getEpoch(iso_timestamp) {
  return new Date(iso_timestamp).getTime();
}

module.exports = {
// export {
  DAY_MS,
  startOfMonthISO,
  daysAddISO,
  todayISO,
  differenceInMonthsISO,
  differenceInDaysISO,
  fromISODateToItalian,
  fromLongUTCISOToItalian,
  timestampLongUTCISO,
  getEpoch
}
