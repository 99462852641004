import { NavLink } from 'react-router-dom';

import { db } from '../../firebase/config';
import { setDoc, doc } from 'firebase/firestore';

import styles from './Reservations.module.css';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const { fromISODateToItalian, fromLongUTCISOToItalian } = require('../../common/iso_dates');

function ReservationList({ id, state, stateSubstitute, SHORT_KEY_LENGTH }) {

  let reservation = state.reservations[id];
  const MySwal = withReactContent(Swal);

  function confirmCancelReservation() {
    MySwal.fire({
      title: <p>Sei sicuro di voler annullare la prenotazione?</p>,
      text: 'Le rate future non verranno addebitate in automatico.',
      icon: 'question',
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonColor: '#cc0000',
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Non procedere',
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          setDoc(doc(db, 'reservations', id), {
            checkin: reservation.checkin,
            checkout: reservation.checkout,
            price: reservation.price,
            stripe_info: reservation.stripe_info,
            timestamp: reservation.timestamp,
            hotel_ref: doc(db, 'hotels', reservation.hotel_id),
            customer_ref: doc(db, 'customers', reservation.customer_id),
            valid: false
          }).then(() => {
            stateSubstitute(`reservations:${id}:valid`, false);
            return MySwal.fire({
              icon: 'success',
              title: 'Operazione Confermata',
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          })
        } catch (error) {
          console.error(error);
        }
      }
    })
  }

  function isScalapay() {
    return !!(reservation.scalapay_info);
  }

  function isKlarna() {
    return !!(reservation.klarna_info);
  }

  return (
    <>
      {!!state.reservations &&
        <div className={styles.container}>
          <p className={styles.item}>{id.slice(0, SHORT_KEY_LENGTH)}</p>
          <p className={styles.item}>{state.hotels[reservation.hotel_id].name + ' #' + reservation.hotel_id.slice(0, SHORT_KEY_LENGTH)}</p>
          <p className={styles.item}>{state.customers[reservation.customer_id].first_name + ' ' + state.customers[reservation.customer_id].last_name}</p>
          <p className={styles.item}>{fromLongUTCISOToItalian(reservation.timestamp)}</p>
          <p className={styles.item}>{fromISODateToItalian(reservation.checkin)}</p>
          <p className={styles.item}>{fromISODateToItalian(reservation.checkout)}</p>
          <p className={styles.item}>{'€ ' + reservation.price}</p>
          <div className={styles.item}>
            <span className={styles.item}>{isKlarna() ? 'Klarna' : isScalapay() ? 'Scalapay' : reservation.valid ? 'Valida' : 'Annullata'}</span>
            {(reservation.valid && !isScalapay() && !isKlarna()) && <span className={styles.item}>[<p onClick={confirmCancelReservation} className={styles.anchor}>annulla</p>]</span>}
          </div>
          {
            !isScalapay() && !isKlarna() && <NavLink to={`/installments/`} onClick={() => { stateSubstitute('search:installments:reservation_id', id.slice(0, SHORT_KEY_LENGTH)) }} className={styles.item + ' link'}>Vedi</NavLink>
          }
        </div>
      }
    </>
  );
}

export default ReservationList;