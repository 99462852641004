
// this is a custom React hook to be used for all our components
// [state, stateSubstitute, stateAdd, stateDelete] = useReducerGeneric({initial_state_params, initState})
// let's destructure to local... if used not for the (olnly) global state, but for a local state, like:
// [localstate, localstateSubstitute, localstateAdd, localstateDelete] = useReducerGeneric({initial_state_params, initState})
import { useReducer } from "react"
import { reducerGeneric, stateChangeGenericDispatch } from '../utils/utils';

export function useReducerGeneric({initial_state_params, initState}) { //initState is a function (if present) acting on initial_state_params to generate initial state

    const [state, dispatch] = useReducer(reducerGeneric, initial_state_params, initState);

    function stateSubstitute(path, part) { return stateChangeGenericDispatch('SUBSTITUTE', path, part, dispatch); }
    function stateAdd(path, part) { return stateChangeGenericDispatch('ADD', path, part, dispatch); }
    function stateDelete(path) { return stateChangeGenericDispatch('DELETE', path, null, dispatch); }

    return [state, stateSubstitute, stateAdd, stateDelete];
}
