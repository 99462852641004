import ReservationList from './ReservationList';
import styles from './Reservations.module.css';

function Reservations({ state, stateSubstitute, SHORT_KEY_LENGTH, MAX_TABLE_ROWS_FOR_PAGE }) {
  const reservations_filterd = Object.entries(state.reservations).filter(([reservation_id, reservation]) =>
    reservationMatchesSearch(reservation_id, reservation /*{ ...value }*/)
  );

  const reservations_sorted = reservations_filterd.sort(
    (
      [first_reservation_id, { ...first_reservation_value }],
      [second_reservation_id, { ...second_reservation_value }]
    ) => second_reservation_value.timestamp.localeCompare(first_reservation_value.timestamp)
  ); // because reservations_filterd is eqaul to an array of array [key: value], example of an element of reservations_filterd 0:['BHV1Rzy71w2QxhZralmA', {…}]

  const reservations_sliced = reservations_sorted.slice(0, MAX_TABLE_ROWS_FOR_PAGE);

  // for documentation see the filter in hotels.js
  function reservationMatchesSearch(reservation_id, reservation) {
    let search = state.search.reservations;

    let customer_name = `${state.customers[reservation.customer_id].first_name} ${state.customers[reservation.customer_id].last_name}`;

    let hotel_name = `${state.hotels[reservation.hotel_id].name} #${reservation.hotel_id.slice(0, SHORT_KEY_LENGTH)}`;

    if (
      (search.id &&
        (!reservation_id ||
          !reservation_id.slice(0, SHORT_KEY_LENGTH).toLowerCase().includes(search.id.toLowerCase()))) ||
      (search.hotel_name &&
        (!hotel_name || !hotel_name.toLowerCase().includes(search.hotel_name.toLowerCase()))) ||
      (search.checkin &&
        (!reservation.checkin ||
          !reservation.checkin.toLowerCase().includes(search.checkin.toLowerCase()))) ||
      (search.checkout &&
        (!reservation.checkout ||
          !reservation.checkout.toLowerCase().includes(search.checkout.toLowerCase()))) ||
      (search.customer_name &&
        (!customer_name ||
          !customer_name.toLowerCase().includes(search.customer_name.toLowerCase()))) ||
      (search.price &&
        (!reservation.price ||
          !reservation.price.toLowerCase().includes(search.price.toLowerCase()))) ||
      (search.timestamp &&
        (!reservation.timestamp ||
          !reservation.timestamp.toLowerCase().includes(search.timestamp.toLowerCase()))) ||
      !checkReservationIsValid(reservation, search)
    ) {
      return false;
    }

    return true;
  }

  function checkReservationIsValid(reservation, search) {
    if (search.valid === 'all') {
      return true;
    } else {
      if (search.valid === 'scalapay') {
        if (!!(reservation.scalapay_info)) {
          return true;
        }
        else {
          return false;
        }
      }
      if (search.valid === 'klarna') {
        if (!!(reservation.klarna_info)) {
          return true;
        }
        else {
          return false;
        }
      }
      if (search.valid === 'valid') {      // check to not be a Scalapay payment
        if (reservation.valid && !(reservation.scalapay_info) && !(reservation.klarna_info)) {
          return true;
        } else {
          return false;
        }
      }
      if (search.valid === 'invalid') {
        if (!reservation.valid && !(reservation.scalapay_info) && !(reservation.klarna_info)) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  return (
    <>
      <h1>Prenotazioni</h1>
      <div className={`${styles.container} ${styles.title}`}>
        <p className={styles.item}>ID Prenotazione</p>
        <p className={styles.item}>Hotel</p>
        <p className={styles.item}>Cliente</p>
        <p className={styles.item}>Creazione</p>
        <p className={styles.item}>Check-in</p>
        <p className={styles.item}>Check-out</p>
        <p className={styles.item}>Prezzo</p>
        <p className={styles.item}>Stato</p>
        <p className={styles.item}>Rate</p>
      </div>
      <div className={`${styles.container} ${styles.filter}`}>
        <input
          className={styles.item}
          placeholder='Cerca per id'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:reservations:id', e.target.value);
          }}
          value={state.search.reservations.id}
        />
        <input
          className={styles.item}
          placeholder='Cerca per hotel'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:reservations:hotel_name', e.target.value);
          }}
          value={state.search.reservations.hotel_name}
        />
        <input
          className={styles.item}
          placeholder='Cerca per cliente'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:reservations:customer_name', e.target.value);
          }}
          value={state.search.reservations.customer_name}
        />
        <input
          className={styles.item}
          placeholder='Cerca per data di creazione'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:reservations:timestamp', e.target.value);
          }}
          value={state.search.reservations.timestamp}
        />
        <input
          className={styles.item}
          placeholder='Cerca per checkin'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:reservations:checkin', e.target.value);
          }}
          value={state.search.reservations.checkin}
        />
        <input
          className={styles.item}
          placeholder='Cerca per checkout'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:reservations:checkout', e.target.value);
          }}
          value={state.search.reservations.checkout}
        />
        <input
          className={styles.item}
          placeholder='Cerca per prezzo'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:reservations:price', e.target.value);
          }}
          value={state.search.reservations.price}
        />
        <select
          className={styles.item}
          onChange={(e) => {
            stateSubstitute('search:reservations:valid', e.target.value);
          }}
          value={state.search.reservations.valid}>
          <option value='all'></option>
          <option value='valid'>Sì</option>
          <option value='invalid'>No</option>
          <option value='scalapay'>Scalapay</option>
          <option value='klarna'>Klarna</option>
        </select>
      </div>
      {reservations_sliced.length ? (
        <>
          {reservations_sliced.map(([id]) => (
            <ReservationList
              key={id}
              id={id}
              state={state}
              stateSubstitute={stateSubstitute}
              SHORT_KEY_LENGTH={SHORT_KEY_LENGTH}
            />
          ))}
        </>
      ) : (
        <p>Nessuna Prenotazione da visualizzare</p>
      )}
    </>
  );
}

export default Reservations;
