// TEMPLATE - copy to src/firebase/config.js and modify accordingly

import { initializeApp } from 'firebase/app';
import {
  getFirestore
} from 'firebase/firestore';
import {
  getAuth
} from 'firebase/auth';

// init firebase - Copy the values from the Project Settings page on the Firebase project's console (and create WEB app with firebase hosting, if not done yet)
initializeApp({
  apiKey: "AIzaSyBFgxioL-aRiyEuHnpBiwV7DX2Te9gJLtg",
  authDomain: "holipay-staging.firebaseapp.com",
  projectId: "holipay-staging",
  storageBucket: "holipay-staging.appspot.com",
  messagingSenderId: "418979399124",
  appId: "1:418979399124:web:b4c99c2597043dc1920241"
});
// init services
const db = getFirestore();
const auth = getAuth();

export { db, auth }