import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useReducerGeneric } from './hooks/useReducerGeneric';

import './App.css';

import Hotels from './pages/hotels/Hotels';
import Reservations from './pages/reservations/Reservations';
import Installments from './pages/installments/Installments';
import Login from './pages/login/Login';
import Navbar from './components/Navbar';

// Example of adding data to database

// db.collection('hotels').add({
//   name: 'Hotel',
//   private_key: 'Chiave Privata',
//   valid: true,
//   note: 'Anhodiabsfiuabs ashbdiusadiabdiuab'
// });

// db.collection('installments').add({
//   amount: '250',
//   date: '2021-12-02',
//   status: 'da pagare',
//   reservation_id: 'R-000001',
//   stripe_payment_id: 'asdfgh123',
// });

// db.collection('reservations').add({
//   checkin: '2021-11-02',
//   checkout: '2021-12-03',
//   timestamp: '2021-12-06T12:54:22.845Z',
//   first_name: 'Paulo',
//   hotel_id: 'gmJiwOyrWqXmsGnPmrmP',
//   last_name: 'Paulo',
//   price: '500.50',
//   email:"",
//   phone: "",
//   valid: true
// });


function App() {
  const SHORT_KEY_LENGTH = 5;
  const MAX_TABLE_ROWS_FOR_PAGE = 100;

  const [state, stateSubstitute, stateAdd] = useReducerGeneric({
    initial_state_params: {
      search: {
        hotels: {
          id: '',
          hotel_name: '',
          hotel_private_key: '',
          stripe_account: '',
          note: '',
          valid: 'all',
          scalapay_key: '',
          klarna_key: '',
        },
        reservations: {
          id: '',
          hotel_name: '',
          checkin: '',
          checkout: '',
          customer_name: '',
          price: '',
          timestamp: '',
          valid: 'all',
        },
        installments: {
          id: '',
          hotel_name: '',
          customer_name: '',
          amount: '',
          date: '',
          reservation_id: '',
          status: 'all',
        },
      },
      hotel_being_modified: {
        name: '',
        note: '',
        valid: true,
        stripe_account: '',
      },
      hotels: {},
      installments: {},
      reservations: {},
      customers: {},
      login: {
        email: '',
        password: '',
      },
      enabled_installments: {}
    },
  });

  return (
    <div className='App'>
      <BrowserRouter>
        <div>
          <h1 className='title'>
            <img src={'/svg/holipay-logo.svg'} alt='logo holipay' style={{ height: '32px' }}></img>
          </h1>
        </div>
        {state.login.uid ? <Navbar stateSubstitute={stateSubstitute}/> : ''}
        <Routes>
          <Route
            path='/'
            element={
              state.login.uid ? (
                <Hotels
                  state={state}
                  stateSubstitute={stateSubstitute}
                  stateAdd={stateAdd}
                  SHORT_KEY_LENGTH={SHORT_KEY_LENGTH}
                  MAX_TABLE_ROWS_FOR_PAGE={MAX_TABLE_ROWS_FOR_PAGE}
                />
              ) : (
                <Navigate to='/login' />
              )
            } // if the user is not unauthenticated, it loads the login page
          />
          <Route
            path='/reservations/'
            element={
              state.login.uid ? (
                <Reservations
                  state={state}
                  stateSubstitute={stateSubstitute}
                  stateAdd={stateAdd}
                  SHORT_KEY_LENGTH={SHORT_KEY_LENGTH}
                  MAX_TABLE_ROWS_FOR_PAGE={MAX_TABLE_ROWS_FOR_PAGE}
                />
              ) : (
                <Navigate to='/login' />
              )
            }
          />
          <Route
            path='/installments/'
            element={
              state.login.uid ? (
                <Installments
                  state={state}
                  stateSubstitute={stateSubstitute}
                  SHORT_KEY_LENGTH={SHORT_KEY_LENGTH}
                  MAX_TABLE_ROWS_FOR_PAGE={MAX_TABLE_ROWS_FOR_PAGE}
                />
              ) : (
                <Navigate to='/login' />
              )
            }
          />
          <Route
            path='/login'
            element={<Login state={state} stateSubstitute={stateSubstitute} stateAdd={stateAdd} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
