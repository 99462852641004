// @ts-check

//TODO: copied from ciaobnb, arragne names and directories when finished

export function deepCopyFunction(inObject){
  let outObject, value, key;
  if (typeof inObject !== 'object' || inObject === null) {
      return inObject; // Return the value if inObject is not an object
  }
  if(inObject instanceof Date){
      return new Date(inObject)
  }
  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};
  for (key in inObject) {
      value = inObject[key];
      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = deepCopyFunction(value);
  }
  return outObject;
}


// stateChangeGenericDispatch is used only to create specific 'shortcut' functions in your component 
// after dispatch is returned (note: use always the same names as below, with the same definition)
// never use stateChangeGenericDispatch directly after defining the 'shortcut' functions below in your component
//
// function stateSubstitute(path,part) { return stateChangeGenericDispatch('SUBSTITUTE',path,part,dispatch);}
// function stateDelete(path) { return stateChangeGenericDispatch('DELETE',path,null,dispatch);}
// function stateAdd(path,part) { return stateChangeGenericDispatch('ADD',path,part,dispatch);}

/**
 * @function stateChangeGenericDispatch
 * @param {string} type
 * @param {string} path
 * @param {any} part
 * @param {any} mydispatch
 */
export const stateChangeGenericDispatch = (type,path,part,mydispatch) => { //default but can be used also with globaldispatch
  mydispatch({
      type: type,
      payload: {
        globalpath: path,
        part: part,
      },
  });
};
    

/**
 * @function reducerGeneric
 * @param {any} state
 * @param {{ payload: { globalpath: string; part: any; }; type: 'SUBSTITUTE'|'DELETE'|'ADD'; }} action
 */
export  const reducerGeneric = (state, action) => {
  // using a "reducer" function (with the React hook useReducer()) is a suggested practice for modifying the state in a React component
  //
  // a reducer centralizes all changes to the component state so that they can be checked independently
  // a reducer takes as read-only inputs the current state, an action (which usually contains 'type' and 'payload') and produces
  // a brand-new state to substitute the old one.
  // A reducer is a pure (side-effect free) function which makes code checking much easier.
  //
  // the underlying theoretical concept is related to a finite-state automaton, transitioning between states when given a signal (and the emitted signal does not care in this case)
  //
  // (state,action) ==> newstate  ...inputs are read-only
  // the presence of a brand-new object is the way in which React recognizes that the state is changed so that it triggers a new reder()
  //
  // reducerGeneric() is used to construct specific reducers when the actions are SUBSTITUTE, DELETE, ADD
  // the location in the state where the change occurs is specified by a 'globapath", null if the entire object is to be changed,
  // or a string separated by ":" to indicate sub-objects of the main object (the path to reach the subtree of interest)
  // E.g.  'globapath' "0:pippo" can identify item 0 in an array and then the object identified with key "pippo" in the array item
  //
  // NOTE: some additional checks and extensions can be added in the future when (and if) needed to support more complex modifications, ask Roberto for additions
  //
  //assumption:
  //  action.type is SUBSTITUTE,DELETE or ADD
  //  action.payload contains:
  //    .part (to be changed or added) can be missing for delete. If a new key:value is added, part must contain part.key and part.value
  //    .globalpath  (in the object) is optional ("" if not defined). It must be a string, separated by ":" if the object is not at the first level of the hierarchy

  //globalpath has to be passed to each component so that it can act on the global state
  const globalpath = 'globalpath' in action.payload ? action.payload.globalpath.trim() : '';
  if (typeof globalpath !== 'string')
    throw new Error('Error globalpath must be a string, while it is now:' + globalpath);

  const part = 'part' in action.payload ? deepCopyFunction(action.payload.part) : null; // part (object) to be used in changes (part is read-only!)
  let subtree = globalpath.length ? globalpath.split(':') : []; //name contains instructions about where in the object to change the state, [] because otherwise [""]
  if (subtree.length && subtree[0] === '') subtree.shift(); //  ["","name"] --> ["name"]

  let new_state = deepCopyFunction(state); //build a brand new state object so that we guarantee state is read-only and not modified "in place"
  let part_to_change = new_state;
  if(process.env.NODE_ENV !== 'production'){
    console.log("subtree",subtree,'type:',('type' in action) ? action.type : '', 'payload:', ('payload' in action && 'part' in action.payload) ? action.payload.part : '');
  }
  switch (action.type) {
    case 'SUBSTITUTE':
      //name contains position in the object tree i.,e.    0:rates:0:rate  (to change a single rate)
      if (subtree.length) {
        for (let i = 0; i < subtree.length - 1; i++) {
          let key = subtree[i];
          if (!(key in part_to_change)) throw new Error('Error no such key:' + key);
          part_to_change = part_to_change[key]; //reference if (and only if) an object!
        }
        let last_key = subtree[subtree.length - 1];
        part_to_change[last_key] = part;
      } else {
        //changing the whole object
        new_state = part;
      }
      if(process.env.NODE_ENV !== 'production'){
        console.log({new_state});
      }
      return new_state;

    case 'DELETE': // now changes so that it works also to eliminate a key in an object
      if (subtree.length) {
        //name contains position in the object tree i.,e.    0:intervals:0:rates:1  (to delete the element)
        let prefinal_key = null;
        let prefinal_part = null;
        for (let i = 0; i < subtree.length - 1; i++) {
          let key = subtree[i];
          if (!(key in part_to_change)) throw new Error('Error no such key:' + key);
          prefinal_part = part_to_change;
          prefinal_key = key;
          part_to_change = part_to_change[key]; //reference if (and only if) an object!
        }
        let last_key = subtree[subtree.length - 1]; // (array to be deleted)
        //console.log(part_to_change, typeof part_to_change, Array.isArray(part_to_change), "key",last_key);

        if(Array.isArray(part_to_change))
          part_to_change = part_to_change.filter((interval, i) => {
            // here i is a number while key is a string, to use the !== I need to make key a number, and I do that with Number(last_key)
            return i !== Number(last_key);
          });
        else if( (typeof part_to_change) == 'object' ){
          delete part_to_change[last_key];
          //console.log(retval,"after deleting", part_to_change,prefinal_part,prefinal_key);
        }

        if (prefinal_key)
          //null if part is not embedded at a level bigger than the first
          prefinal_part[prefinal_key] = part_to_change;
        else new_state = part_to_change;
      }
      if(process.env.NODE_ENV !== 'production'){
        console.log({new_state});
      }
      return new_state;

    case 'ADD': //if adding to an array or to an object. In the second case part.key and part.value must both be present
      for (let i = 0; i < subtree.length; i++) {
        let key = subtree[i];
        if (!(key in part_to_change)) throw new Error('Error no such key:' + key);
        part_to_change = part_to_change[key]; //reference if (and only if) an object!
      }
      if (Array.isArray(part_to_change)) part_to_change.push(part); 
      else part_to_change[part.key] = part.value; // addink key: val
      if(process.env.NODE_ENV !== 'production'){
        console.log({new_state});
      }
      return new_state;

    default:
      return new_state;
  }
};
