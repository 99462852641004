import { auth } from '../../firebase/config';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { useNavigate } from 'react-router-dom';

import styles from './Login.module.css';

function Login({ state, stateAdd, stateSubstitute }) {
  const error_message = {
    'auth/invalid-email': 'Email invalida.',
    'auth/internal-error': 'Password non fornita.',
    'auth/wrong-password': 'Email o password sbagliate.',
    'auth/user-not-found': 'Email o password sbagliate.',
    'auth/network-request-failed': 'Erorre: connessione al server non riuscita.'
  };
  const navigate = useNavigate();

  async function tryLoginWithPassword(e) {
    e.preventDefault();

    try {
      let user = await signInWithEmailAndPassword(auth, state.login.email, state.login.password)

      stateAdd('login', { key: 'uid', value: user.user.uid });
      navigate('/');

    } catch (error) {
      stateAdd('login', { key: 'error', value: error.code });
    }
  }

  return (
    <div className={styles['login-form']}>
      <form onSubmit={(e) => tryLoginWithPassword(e)}>
        <h2>Login</h2>
        <label>
          <span>email:</span>
          <input
            type='email'
            onChange={(e) => stateSubstitute('login:email', e.target.value)}
            value={state.login.email}
          />
        </label>
        <label>
          <span>password:</span>
          <input
            type='password'
            onChange={(e) => stateSubstitute('login:password', e.target.value)}
            value={state.login.password}
          />
        </label>
        <button>Login</button>
        {state.login.error && <p>{error_message[state.login.error] ? error_message[state.login.error]: 'Errore inatteso'}</p>}
      </form>
    </div>
  );
}

export default Login;